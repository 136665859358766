import { Typography } from "@mui/material";
import { useMemo } from "react";
import { edition, version } from "./utils";
import styles from "./version.module.scss";

interface BindplaneVersionProps {
  licenseType?: string;
}

// Version displays the server version received from the /version endpoint.
export const BindplaneVersion: React.FC<BindplaneVersionProps> = ({
  licenseType,
}) => {
  const license = useMemo(() => {
    if (licenseType) {
      return licenseType;
    }

    return edition();
  }, [licenseType]);

  let label = useMemo(() => {
    switch (license) {
      case "Honeycomb":
        return `BindPlane for Honeycomb ${version()}`;
      default:
        return `${license} Edition ${version()}`;
    }
  }, [license]);

  return (
    <Typography
      variant="body2"
      fontWeight={300}
      classes={{ root: styles.root }}
    >
      {label}
    </Typography>
  );
};
