import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { acceptEula } from "../../utils/rest/accept-eula";
import styles from "./eula-required.module.scss";

const EulaRequiredPageContent: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  async function handleContinueClick() {
    try {
      await acceptEula();
      navigate("/overview");
    } catch (e) {
      enqueueSnackbar("Could not accept EULA", { variant: "error" });
    }
  }

  return (
    <Stack
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      className={styles.stack}
    >
      <Card classes={{ root: styles.card }}>
        <CardHeader title="EULA Required" />
        <CardContent>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                  }}
                />
              }
              label={
                <Typography variant="body2">
                  I accept the{" "}
                  <a
                    href="https://observiq.com/legal/eula"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    End User License Agreement
                  </a>
                  .
                </Typography>
              }
            />
          </FormGroup>
        </CardContent>
        <CardActions className={styles.actions}>
          <Button
            disabled={!checked}
            onClick={handleContinueClick}
            variant="contained"
          >
            Continue
          </Button>
        </CardActions>
      </Card>
    </Stack>
  );
};

export const EulaRequiredPage = withRequireLogin(EulaRequiredPageContent);
